.hoox-shop {
    padding: 48px 0;
}

.hoox-shop__container {
    display: flex;
    flex-direction: column;
    gap: 35px;
}


/* Gallery CSS */
.hoox-shop__gallery {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;
}

.hoox-shop__gallery-main {
    width: calc(100% - 1px);
}

.hoox-shop__gallery-main-image {
    width: 100%;
    margin-inline: auto;
    flex-shrink: 0;
    height: 290px;
}

.hoox-shop__gallery-thumbs {
    max-width: 100%;
}

.hoox-shop__gallery-thumbs .swiper-slide {
    width: 56px;
    max-height: 50px;
}

.hoox-shop__gallery-thumbs .swiper-slide figure {
    width: 56px;
    height: 50px;
}

.hoox-shop__gallery-thumbs .swiper-slide figure img {
    width: 56px;
    max-height: 50px;
}

.hoox-shop__gallery-thumbs .swiper-slide-thumb-active figure {
    border: 1px solid var(--hoox-color-slate-secondary);
    padding: 4px;
}

.hoox-shop__ingredients-triger {
    text-decoration: underline;
}


.hoox-shop__product-info {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.hoox-shop__reviews {
    display: flex;
    gap: 8px;
    margin-bottom: 12px;
    order: 1;
}

.product__reviewsStars {
    display: flex;
    align-items: center;
}

.product__reviewsStars span {
    height: 15px;
    width: 15px;
    margin-right: 4px;
}

.product__reviewsCount {
    color: var(--hoox-color-slate-secondary);
    font-size: 14px;
    font-weight: 400;
    line-height: 120%;
    text-transform: capitalize;
    margin-top: 3px;
}

.hoox-shop__product-pretitle {
    color: var(--hoox-color-slate-secondary);
    font-size: 20px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 12px;
    order: 2;
}

.hoox-shop__product-title {
    margin-bottom: 16px;
    order: 3;
}

.hoox-shop__product-description {
    margin-bottom: 24px;
    order: 4;
}

.hoox-shop__whats-inside-title {
    color: var(--hoox-color-slate-secondary);
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 12px;
    order: 5;
}

.hoox-shop__whats-inside {
    color: var(--hoox-color-slate-secondary);
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 15px;
    font-weight: 400;
    line-height: 130%;
    margin-bottom: 32px;
    order: 6;
}

.hoox-shop__gift-box {
    padding: 0 12px;
    display: flex;
    align-items: center;
    gap: 16px;
    background: #FAFAFA;
    border: 1px solid var(--hoox-color-slate-primary);
    margin-bottom: 32px;
    order: 8;
}

.hoox-shop__gift-image {
    max-height: 65px;
    width: 80px;
    flex-shrink: 0;
}

.hoox-shop__gift-image img {
    max-height: 65px;
    width: 80px;
    object-fit: cover;
}

.hoox-shop__gift-content {
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
    max-width: 200px;
}

.hoox-shop__actions {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 32px;
    order: 7;
}

.quantity-input {
    height: 46px;
    flex-shrink: 0;
}

#addToBagLink {
    width: 100%;
    min-width: unset;
    height: 46px;
    font-size: 14px;
    text-transform: uppercase;
}

#productComparePrice {
    text-decoration: line-through;
    color: #C6CACD;
}

.hoox-shop__price {
    margin-left: 5px;
}

.hoox-shop__price--compare {
    margin-left: 5px;
}


.hoox-shop__icons {
    display: flex;
    justify-content: center;
    gap: 16px;
    margin-bottom: 32px;
    order: 9;
}

.hoox-shop__icon {
    max-width: 70px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.hoox-shop__icon-caption {
    text-align: center;
    font-size: 13px;
    font-weight: 400;
    line-height: 130%;
}

.hoox-shop__results-title {
    font-size: 18px;
    line-height: normal;
    margin-bottom: 16px;
    order: 10;
}

.hoox-shop__results {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    order: 11;
}

.hoox-shop__result {
    display: flex;
    gap: 16px;
    align-items: center;
    padding-bottom: 16px;
    border-bottom: 1px solid #EAEDEF;
}

.hoox-shop__result-number {
    font-size: 28px;
    line-height: normal;
}

.hoox-shop__result-text {
    font-size: 16px;
    line-height: 150%;
}

.hoox-shop__footnotes {
    color: var(--hoox-color-slate-medium);
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 12px;
    line-height: 130%;
    order: 12;
}

/*SHIPPING INFO*/

.hoox-shop__shopping-infos {
    order: 7;
    font-size: 14px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.hoox-shop__actions {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 10px;
    order: 7;
}

.hoox-shop__shipping-info {
    text-align: center;
    font-size: 13px;
}

.hoox-shop__available-info {
    display: flex;
    gap: 3px;
    align-items: center;
    font-size: 13px;
}

/*BADGE*/

.hoox-shop__badge {
    background: #414D57;
    color: var(--hoox-color-white);
    width: 85px;
    height: 22px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    line-height: 120%;
    padding: 6px 8px;
    white-space: nowrap;
    position: absolute;
    z-index: 99;
    right: 8px;
    top: 8px;
}

.hoox-shop__select-wrapper.hoox-shop__select-wrapper--size {
    display: flex;
    flex-direction: column-reverse;
}

@media(min-width:768px) {
    .hoox-shop__container {
        flex-direction: row;
        gap: 80px;
    }

    .hoox-shop__shopping-infos {
        order: 8;
        margin-left: 120px;
    }

    .hoox-shop__shipping-info {
        font-size: 14px;
    }

    .hoox-shop__available-info {
        font-size: 14px;
    }

    /* Gallery CSS */
    .hoox-shop__gallery {
        width: 530px;
        height: 543px;
    }

    .hoox-shop__gallery-main {
        width: 530px;
        height: 420px;
    }

    .hoox-shop__gallery-main .swiper-slide {
        display: flex;
    }

    .hoox-shop__gallery-main-image {
        width: 530px;
    }

    .hoox-shop__gallery-thumbs .swiper-slide {
        height: 60px;
    }


    .hoox-shop__gallery-thumb {
        width: 100%;
        margin: auto;
        height: 100%;
        cursor: pointer;
    }

    .hoox-shop__reviews {
        margin-bottom: 14px;
    }
    .hoox-shop__product-pretitle {
        font-size: 24px;
    }

    .hoox-shop__product-description {
        font-size: 18px;
        line-height: 23px;
        margin-bottom: 24px;
    }

    .hoox-shop__whats-inside-item {
        font-size: 16px;
        line-height: 21px;
    }

    .hoox-shop__actions {
        order: 8;
        gap: 12px;
    }

    .hoox-shop__gift-box {
        gap: 24px;
        height: 80px;
        margin-bottom: 40px;
    }

    .hoox-shop__product-info {
        max-width: 560px;
    }

    #addToBagLink {
        height: 47px;
    }

    .quantity-input {
        height: 47px;
    }

    .hoox-shop__icons {
        justify-content: flex-start;
        margin-bottom: 40px;
    }

    .hoox-shop__icon {
        max-width: 128px;
        align-items: center;
    }

    .hoox-shop__icon-caption {
        font-size: 16px;
    }

    .hoox-shop__results-title {
        margin-bottom: 24px;
    }

    .hoox-shop__result-number {
        font-size: 32px;
        width: 67px;
    }

    .hoox-shop__footnotes {
        font-size: 14px;
    }



    /*GALLERY*/

    .hoox-shop__gallery {
        width: 560px;
        height: 543px;
        display: flex;
        flex-direction: row-reverse;
        gap: 28px;
        position: relative;
    }

    .hoox-shop__gallery-main {
        width: 464px;
        height: 500px;
    }


    .hoox-shop__gallery-thumbs {
        max-width: 100%;
        height: 500px;
        overflow: hidden !important;
        padding-bottom: 3px;
    }

    .hoox-shop__gallery-thumbs .swiper-slide {
        width: 64px;
        max-height: 70px;
    }

    .hoox-shop__gallery-thumbs .swiper-slide figure {
        width: 64px;
        height: 70px;
    }

    .hoox-shop__gallery-thumbs .swiper-slide figure img {
        width: 64px;
        max-height: 70px;
    }

    .hoox-shop__gallery-main-image {
        width: 464px;
        height: 500px;
    }

    .hoox-shop__ingredients-triger{
        width: 100%;
        position: absolute;
        bottom: 0;
    }
    .hoox-shop__icon img {
        max-width: 60px;
        max-height: 60px;
    }
    .hoox-shop__gift-box {
        padding: 0px 24px;
    }

    .hoox-shop__gift-content {
        max-width: 100%;
    }

    /*BADGE*/

    .hoox-shop__badge {
        width: 109px;
        height: 29px;
        font-size: 18px;
        padding: 6px 8px;
        right: 12px;
        top: 12px;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation:portrait){
    .hoox-shop__container {
        flex-direction: column;
        gap: 43px;
        align-items: center;
    }
}

.hoox-shop__whats-inside-icon {
    display: none;
}

.hoox-shop-lp-2 .hoox-shop__whats-inside-icon, .hoox-shop-lp-3 .hoox-shop__whats-inside-icon {
    display: block;
}

.hoox-shop-lp-2 .hoox-shop__whats-inside-item, .hoox-shop-lp-3 .hoox-shop__whats-inside-item {
    display: flex;
    align-items: center;
    gap: 12px;
}

@media (min-width: 768px) {
    .hoox-shop-lp-2 .hoox-shop__whats-inside, .hoox-shop-lp-3 .hoox-shop__whats-inside {
        flex-direction: row;
        flex-wrap: wrap;
    }
    .hoox-shop-lp-2 .hoox-shop__whats-inside-item, .hoox-shop-lp-3 .hoox-shop__whats-inside-item {
        min-width: 240px;
    }
} 

.hoox-shop__options {
    order: 6;
    margin-bottom: 32px;
}

.hoox-shop__option-name {
    font-style: italic;
}
.hoox-shop__values {
    display: flex;
    gap: 12px;
}

.hoox-shop__value {
    border: 1px solid var(--hoox-color-slate-medium);
    background: var(--hoox-color-grey);
    width: 104px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hoox-shop__value svg {
    margin-right: 5px;
}

.hoox-shop__selected {
    margin-bottom: 16px;
}

.hoox-shop__value.active {
    background-color: var(--hoox-color-grey3);
}

@media (min-width: 768px) {
    .hoox-shop__value {
        width: 179px;
        cursor: pointer;
        font-size: 18px;
    }
    .hoox-shop__options {
        margin-bottom: 40px;
    }
}

.hoox-shop-lp-2 .hoox-shop__product-description {
    margin-bottom: 16px;
}

.hoox-shop-lp-2 .hoox-shop__whats-inside-title {
    margin-bottom: 16px;
}

.hoox-shop-lp-2 .hoox-shop__whats-inside-item {
    font-size: 16px;
}

@media(min-width: 767px) {
    .hoox-shop-lp-2 .hoox-shop__whats-inside-title {
        margin-bottom: 24px;
    }
}

@media (max-width: 1023px) {
    .hoox-shop-lp-2 .hoox-shop__gallery-lp-2, .hoox-shop-lp-3 .hoox-shop__gallery-lp-3 {
        order: 6;
        margin-bottom: 32px;
    }

    .hoox-shop-lp-2 .hoox-shop__reviews {
        margin-bottom: 6px;
    }
    
    .hoox-shop-lp-2 .hoox-shop__product-pretitle, .hoox-shop-lp-3 .hoox-shop__product-pretitle {
        margin-bottom: 8px;
    }
    .hoox-shop-lp-3 .hoox-shop__product-title {
        font-size: 26px;
    }
}

.hoox-shop-lp-3 .product__reviewsStars span {
    width: 16px;
}

.hoox-shop-lp-3 .hoox-shop__reviews {
    margin-bottom: 3px;
}

.hoox-shop-lp-3 .hoox-shop__value {
    width: 50%;
    font-size: 18px !important;
}

.hoox-shop-lp-3 #addToBagLink {
    font-size: 16px;
}

@media (max-width: 767px) {
    .hoox-shop-lp-3 {
        padding-top: 40px;
    }
    
    .hoox-shop-lp-3 .hoox-shop__product-title {
        margin-bottom: 14px;
    }
    
    .hoox-shop-lp-3 .hoox-shop__whats-inside-title {
        margin-bottom: 23px;
    }
    
    .hoox-shop-lp-3 .hoox-shop__whats-inside {
        font-size: 14px;
    }
    
    .hoox-shop-lp-3 .hoox-shop__whats-inside-item {
        gap: 6px;
    }
    
    .hoox-shop-lp-3 .hoox-shop__whats-inside {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 6px;
        margin-bottom: 18px;
    }
    .hoox-shop-lp-3 .hoox-shop__whats-inside-item {
        width: 151px;
    }
    
    .hoox-shop-lp-3 .hoox-shop__gallery-main-image {
        height: 275px;
    }
    
    .hoox-shop-lp-3 .hoox-shop__selected label {
        font-size: 18px;
    }
    
    .hoox-shop-lp-3 .hoox-shop__options {
        margin-bottom: 32px;
    }
    
    .hoox-shop-lp-3 .quantity-input__button {
        padding: 6px 10px;
    }
    
    .hoox-shop-lp-3 .hoox-shop__shipping-info {
        font-size: 12px;
    }
    
    .hoox-shop-lp-3 .hoox-shop__shopping-infos {
        margin-bottom: 32px;
    }
    
    .hoox-shop-lp-3 .hoox-shop__gallery-thumbs .swiper-slide-thumb-active figure {
        padding: 0;
    }
    
    .hoox-shop-lp-3 .hoox-shop__gallery-thumbs .swiper-slide {
        max-height: 60px;
    }
    
    .hoox-shop__gallery-thumbs .swiper-slide figure img {
        width: 60px;
        max-height: 60px;
    }
    
    .hoox-shop-lp-3 .hoox-shop-lp-3 .hoox-shop__gallery-lp-3 {
        margin-bottom: 19px;
    }
}
 .hoox-shop-lp-3 .hoox-shop__icons {
        margin-bottom: 0;
    }

@media (min-width: 767px) {
    .hoox-shop-lp-3 {
        padding: 80px 0;
    }
    .hoox-shop-lp-3 .hoox-shop__product-pretitle {
        margin-bottom: 1px;
    }
    .hoox-shop-lp-3 .hoox-shop__whats-inside {
        gap: 2px;
        margin-bottom: 24px;
    }
    .hoox-shop-lp-3 .hoox-shop__shopping-infos {
        margin-bottom: 36px;
    }
   
    .hoox-shop-lp-3 .hoox-shop__gallery-thumbs .swiper-slide {
        max-height: 70px;
    }
    
    .hoox-shop__gallery-thumbs .swiper-slide figure img {
        width: 64px;
        max-height: 70px;
    }
    .hoox-shop-lp-3 .hoox-shop-popup__content {
        max-height: unset;
        overflow: unset;
    }
    .hoox-shop-lp-3 .hoox-shop-popup__inner:after {
        display: none;
    }
    .hoox-shop-lp-3 .hoox-shop-popup__body {
        height: unset;
    }
    .hoox-shop-lp-3 .hoox-shop-popup__content p {
        font-size: 14px;
        line-height: 21px;
    }
}

